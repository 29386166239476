import React from 'react';

import Select from 'react-select/async';

import { AlgoliaService } from '/services';

const SearchField = () => {
    const searchClient = AlgoliaService.getSearchClient();

    const loadOptions = (value, callback) => {
        searchClient.search(value, { hitsPerPage: 6 }).then(result => {
            const options = result.hits.map(hit => ({
                key: hit.title,
                label: hit.title,
                url: hit.url,
            }));

            callback(options);
        });
    };

    const onChange = value => {
        if (value) {
            window.location = value.url;
        }
    };

    return (
        <Select
            menuIsOpen
            className="search-field"
            classNamePrefix="search-field"
            cacheOption
            components={{
                LoadingIndicator: () => null,
                DropdownIndicator: () => (
                    <div className="search-field__indicator">
                        <svg viewBox="0 0 24 24">
                            <use xlinkHref="#icon-search" />
                        </svg>
                    </div>
                ),
                IndicatorSeparator: () => null,
            }}
            loadOptions={loadOptions}
            onChange={onChange}
            placeholder="Zoeken.."
            noOptionsMessage={() => null}
            loadingMessage={() => null}
        />
    );
};

export { SearchField };
