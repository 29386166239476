class LocalStorageService {
    static set = (key, value) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            // progressive enhancement
        }
    };

    static get = (key) => {
        try {
            const value = window.localStorage.getItem(key);

            if (value) {
                return JSON.parse(value);
            }

            return null;
        } catch (error) {
            return null;
        }
    };
}

export { LocalStorageService };
