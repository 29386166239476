import React from 'react';
import PropTypes from 'prop-types';

import { SearchBox } from 'react-instantsearch-dom';

const Submit = (
    <svg viewBox="0 0 24 24">
        <use xlinkHref="#icon-search" />
    </svg>
);

const Reset = (
    <svg viewBox="0 0 24 24">
        <use xlinkHref="#icon-close" />
    </svg>
);

const SearchField = ({ searchable, placeholder }) => (
    <SearchBox
        submit={Submit}
        reset={Reset}
        searchable={searchable}
        translations={{
            placeholder,
        }}
    />
);

SearchField.propTypes = {
    searchable: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
};

SearchField.defaultProps = {
    searchable: false,
};

export { SearchField };
