class ApiService {
    static post = async (endpoint, data) => {
        const body = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            body.append(key, value);
        });

        const response = await fetch(endpoint, {
            method: 'post',
            headers: {
                'X-CSRF-Token': window.csrfTokenValue,
                'Accept': 'application/json',
            },
            body,
        });

        return response.json();
    };
}

export { ApiService };
