import { delay, tween, styler } from 'popmotion';

(() => {
    const sidebarNavigationStyler = styler(
        document.querySelector('[data-navigation-sidebar]'),
    );
    const sidebarNavigationBackgroundStyler = styler(
        document.querySelector('[data-navigation-sidebar-background]'),
    );
    const sidebarNavigationContentStyler = styler(
        document.querySelector('[data-navigation-sidebar-content]'),
    );

    const open = () => {
        sidebarNavigationStyler.set('display', 'block');
        delay(50).start({
            complete: () => {
                tween({
                    from: 0,
                    to: 100,
                }).start({
                    update: value => {
                        sidebarNavigationBackgroundStyler.set('opacity', value);
                    },
                });

                tween({
                    from: sidebarNavigationContentStyler.get('width'),
                    to: 0,
                }).start({
                    update: value => {
                        sidebarNavigationContentStyler.set('x', value);
                    },
                });
            },
        });
    };

    const close = () => {
        tween({
            from: 100,
            to: 0,
        }).start({
            update: value => {
                sidebarNavigationBackgroundStyler.set('opacity', value);
            },
        });

        tween({
            from: 0,
            to: sidebarNavigationContentStyler.get('width'),
        }).start({
            update: value => {
                sidebarNavigationContentStyler.set('x', value);
            },
            complete: () => {
                sidebarNavigationStyler.set('display', 'none');
            },
        });
    };

    document
        .querySelector('[data-navigation-sidebar-open]')
        .addEventListener('click', open);
    document
        .querySelector('[data-navigation-sidebar-close]')
        .addEventListener('click', close);
})();
