import React from 'react';

import { SearchField, SearchHits } from '/components/search/components';

const SearchMobile = () => (
    <div className="mobile-search">
        <div className="block is-medium">
            <SearchField placeholder="Zoek op omschrijving, ingrediënten, ..." />
        </div>
        <div className="block is-full-width with-large-margin">
            <SearchHits />
        </div>
    </div>
);

export { SearchMobile };
