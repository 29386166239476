import React from 'react';

import { connectInfiniteHits } from 'react-instantsearch-dom';

import { SearchHit } from '/components/search/components';

const InternalSearchHits = ({ hits, hasMore, refineNext }) => (
    <div>
        <div className="block is-full-width">
            <div className="teaser-grid">
                {hits.map((hit) => (
                    <SearchHit hit={hit} key={hit.id} />
                ))}
            </div>
        </div>
        {hasMore && (
            <div className="block with-large-margin">
                <div className="button-row align-center">
                    <button className="button" onClick={refineNext}>
                        Meer laden
                    </button>
                </div>
            </div>
        )}
    </div>
);

const SearchHits = connectInfiniteHits(InternalSearchHits);

export { SearchHits };
