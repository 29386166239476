import React from 'react';
import ReactDOM from 'react-dom';

import { Search } from '/components/search/components';

(() => {
    const searchContainer = document.querySelector('[data-search-container]');

    if (searchContainer) {
        ReactDOM.render(
            <Search />,
            searchContainer,
        );
    }
})();
