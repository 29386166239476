import React from 'react';
import PropTypes from 'prop-types';

const ResponsiveImage = ({ className, transforms, width, height, alt, role }) => {
    const srcset = [];
    const sizes = [];

    transforms.forEach((transform) => {
        const {
            src,
            width,
            breakpoint,
        } = transform; // eslint-disable-line no-shadow

        srcset.push(`${src}&dpr=1&q=65 ${width}w`);
        srcset.push(`${src}&dpr=2&q=65 ${width * 2}w`);

        sizes.push(breakpoint);
    });

    const src = `${transforms[0].src}&dpr=1&q=80`;

    return (
        <img
            className={className}
            src={src}
            srcSet={srcset.join(', ')}
            sizes={sizes.join(', ')}
            width={width}
            height={height}
            decoding="async"
            loading="lazy"
            alt={alt}
            role={role}
        />
    );
};

ResponsiveImage.propTypes = {
    className: PropTypes.string,
    transforms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    alt: PropTypes.string.isRequired,
    role: PropTypes.string,
};

ResponsiveImage.defaultProps = {
    className: '',
    width: 0,
    height: 0,
    role: '',
};

export { ResponsiveImage };
