const nl = {
    forms: {
        login: {
            username: 'Gebruikersnaam',
            password: 'Paswoord',
            submit: 'Inloggen',
        },
        register: {
            // todo
        },
    },
};

export { nl };
