import React from 'react';
import PropTypes from 'prop-types';

import { RecipeTeaser } from '/components/recipe-teaser/components';

const SearchHit = ({ hit }) => (
    <RecipeTeaser recipe={hit} />
);

SearchHit.propTypes = {
    hit: PropTypes.shape().isRequired,
};

export { SearchHit };
