/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import ReactDOM from 'react-dom';

import { SearchField } from '/components/search-field/components';

(() => {
    const searchFieldContainers = document.querySelectorAll(
        '[data-search-field-container]',
    );

    searchFieldContainers.forEach(searchFieldContainer => {
        ReactDOM.render(<SearchField />, searchFieldContainer);
    });
})();
