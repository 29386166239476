import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Flickity from 'flickity';
import 'flickity/dist/flickity.css';

import { RecipeTeaser } from '/components/recipe-teaser/components';

import { AlgoliaService } from '/services';

const RelatedRecipes = ({ recipe }) => {
    const flickityContainer = useRef(null);
    let flickity = useRef(null);

    const [relatedRecipes, setRelatedRecipes] = useState([]);

    useEffect(() => {
        const fetch = async () => {
            const searchClient = AlgoliaService.getSearchClient();
            const results = await searchClient.search('', {
                filters: `NOT title:"${
                    recipe.title
                }" AND (${recipe.ingredients
                    .map(ingredient => `facets.ingredients:"${ingredient}"`)
                    .join(' OR ')})`,
                sumOrFiltersScores: true,
                hitsPerPage: 20,
            });

            setRelatedRecipes(results.hits);
        };

        fetch().then();
    }, [recipe]);

    useEffect(() => {
        if (flickityContainer.current && relatedRecipes.length) {
            new Flickity(flickityContainer.current, {
                cellAlign: 'left',
                contain: true,
                groupCells: true,
                pageDots: false,
            });
        }
    }, [flickityContainer, relatedRecipes]);

    return (
        <div className="teaser-carousel" ref={flickityContainer}>
            {relatedRecipes.map(relateRecipe => (
                <RecipeTeaser recipe={relateRecipe} key={relateRecipe.id} />
            ))}
        </div>
    );
};

RelatedRecipes.propTypes = {
    recipe: PropTypes.shape({
        title: PropTypes.string,
        ingredients: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};

export { RelatedRecipes };
