const convertParametersStringToObject = (string = '', keys = []) => {
    const parts = string.split(',');
    const object = {};

    keys.forEach((key, index) => {
        object[key] = parts[index];
    });

    return object;
};

const fillInMessageAttributes = (message, attributeName, parameters) => {
    let cleanMessage = message;
    cleanMessage = cleanMessage.replace(':attribute', attributeName);

    Object.entries(parameters).forEach(([key, value]) => {
        cleanMessage = cleanMessage.replace(`:${key}`, value);
    });

    return cleanMessage;
};

class ValidationService {
    static rules = {
        between: {
            check: (values, key, parameters) => {
                const value = values[key];
                const { min, max } = parameters;

                return value < min || value > max;
            },
            convertParametersStringToObject: parameters =>
                convertParametersStringToObject(parameters, ['min', 'max']),
        },
        required: {
            check: (values, key) => {
                const value = values[key];

                if (Boolean(value) === false) {
                    return false;
                }

                if (typeof value === 'object' && value.length === 0) {
                    return false;
                }

                return true;
            },
            // eslint-disable-next-line max-len
            convertParametersStringToObject: parameters =>
                convertParametersStringToObject(parameters, []),
        },
    };

    static validate = (values, rules, attributeNames, messages) => {
        const errors = {};

        Object.entries(rules).forEach(([attribute, rulesString]) => {
            rulesString.split('|').forEach(ruleString => {
                const parts = ruleString.split(':');
                const key = parts[0];
                const rule = ValidationService.rules[key];
                const parameters = rule.convertParametersStringToObject(
                    parts[1],
                );

                if (rule.check(values, attribute, parameters) === false) {
                    const message = messages[key];
                    const attributeName = attributeNames[attribute];

                    errors[attribute] = [];
                    errors[attribute].push(
                        fillInMessageAttributes(
                            message,
                            attributeName,
                            parameters,
                        ),
                    );
                }
            });
        });

        return errors;
    };
}

export { ValidationService };
