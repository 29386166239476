/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import ReactDOM from 'react-dom';

import { RelatedRecipes } from '/components/related-recipes/components';

(async () => {
    const relatedRecipesContainer = document.querySelector(
        '[data-related-recipes]',
    );

    if (relatedRecipesContainer) {
        const { recipe } = window;

        ReactDOM.render(
            <RelatedRecipes recipe={recipe} />,
            relatedRecipesContainer,
        );
    }
})();
