import React from 'react';

import {RefinementList, SearchBox} from 'react-instantsearch-dom';

const Submit = (
    <svg viewBox="0 0 24 24">
        <use xlinkHref="#icon-search" />
    </svg>
);

const Reset = (
    <svg viewBox="0 0 24 24">
        <use xlinkHref="#icon-close" />
    </svg>
);

const SearchRefinementList = ({ label, attribute }) => (
    <div className="search-filter">
        <h3 className="headline-3">{label}</h3>
        <RefinementList
            submit={Submit}
            reset={Reset}
            attribute={attribute}
            operator="and"
            searchable={true}
            showMore={true}
            translations={{
                showMore: expanded => expanded ? 'Toon minder' : 'Toon meer',
                placeholder: 'Filteren',
                noResults: 'Geen resultaten',
            }}
        />
    </div>
);

export { SearchRefinementList };
