import React from 'react';

const SearchDropdownFilter = ({ label, singular, plural, attribute }) => (
    <div className="search-dropdown-filter">
        <button className="search-dropdown-filter__toggle">
            {label}
            <span className="search-dropdown-filter__toggle-icon">
                <svg viewBox="0 0 24 24">
                    <use xlinkHref="#icon-dropdown" />
                </svg>
            </span>
        </button>
    </div>
);

export { SearchDropdownFilter };
