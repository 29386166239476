import Polyglot from 'node-polyglot';

import { nl } from '/copy';

class CopyService {
    static polyglot;

    static getPolyglot = () => {
        if (CopyService.polyglot) {
            return CopyService.polyglot;
        }

        CopyService.polyglot = new Polyglot({
           phrases: nl,
        });

        return CopyService.getPolyglot();
    };

    static get = (key, attributes) => {
        const polyglot = CopyService.getPolyglot();

        return polyglot.t(key, attributes);
    };
}

export { CopyService };
