import React from 'react';
import PropTypes from 'prop-types';

import { ResponsiveImage } from '/components/responsive-image/components';

import '/components/recipe-teaser/styles/recipe-teaser.scss';

const RecipeTeaser = ({ recipe }) => (
    <a className="recipe-teaser" href={recipe.url}>
        {recipe.images.slice(0, 1).map((image) => (
            <ResponsiveImage
                className="recipe-teaser__image"
                transforms={[
                    {
                        src: `${image}?auto=format,compress&fit=crop&crop=entropy&w=320&h=512`,
                        width: '320',
                        breakpoint: '320px',
                    },
                ]}
                width={320}
                height={512}
                alt={recipe.title}
                role="presentation"
                key={image}
            />
        ))}
        <div className="recipe-teaser__content">
            <div className="recipe-teaser__title headline-2">{recipe.title}</div>
        </div>
    </a>
);

RecipeTeaser.propTypes = {
    recipe: PropTypes.shape({
        images: PropTypes.arrayOf(PropTypes.string),
        url: PropTypes.string,
        title: PropTypes.string,
    }).isRequired,
};

export { RecipeTeaser };
