window.addEventListener(
    'load',
    () => {
        const element = document.createElement('script');

        element.src =
            'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        element.setAttribute('data-ad-client', 'ca-pub-6578820584711154');
        document.body.appendChild(element);
    },
    false,
);
