import React from 'react';

import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { SearchMobile, SearchDesktop } from '/components/search/components';

import { AlgoliaService } from '/services';

const Search = () => {
    const {
        ALGOLIA_INDEX,
    } = window.env;

    const searchClient = AlgoliaService.getReactSearchClient();

    return (
        <InstantSearch indexName={ALGOLIA_INDEX} searchClient={searchClient}>
            <Configure
                hitsPerPage={24}
            />
            <SearchMobile />
            <SearchDesktop />
        </InstantSearch>
    );
};

export { Search };
