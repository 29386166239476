import React from 'react';

import {
    SearchField,
    SearchRefinementList,
    SearchHits,
} from '/components/search/components';

const SearchDesktop = () => (
    <div className="desktop-search">
        <div className="block is-medium">
            <SearchField placeholder="Zoek op omschrijving, ingrediënten, ..." />
        </div>
        <div className="block is-full-width with-giant-margin">
            <div className="desktop-search-content">
                <div className="desktop-search-content__filters">
                    <SearchRefinementList label="Ingrediënten" attribute="facets.ingredients" />
                    <SearchRefinementList label="Smaken" attribute="facets.flavours" />
                </div>
                <div className="desktop-search-content__hits">
                    <SearchHits />
                </div>
            </div>
        </div>
    </div>
);

export { SearchDesktop };
