import algoliasearch from 'algoliasearch/lite';

class AlgoliaService {
    static searchClient = null;
    static reactSearchClient = null;

    static getSearchClient = () => {
        if (AlgoliaService.searchClient) {
            return AlgoliaService.searchClient;
        }

        const {
            ALGOLIA_APPLICATION_ID,
            ALGOLIA_SEARCH_API_KEY,
            ALGOLIA_INDEX,
        } = window.env;

        const searchClient = algoliasearch(
            ALGOLIA_APPLICATION_ID,
            ALGOLIA_SEARCH_API_KEY,
        );

        AlgoliaService.searchClient = searchClient.initIndex(ALGOLIA_INDEX);

        return AlgoliaService.getSearchClient();
    };

    static getReactSearchClient = () => {
        if (AlgoliaService.reactSearchClient) {
            return AlgoliaService.reactSearchClient;
        }

        const {
            ALGOLIA_APPLICATION_ID,
            ALGOLIA_SEARCH_API_KEY,
        } = window.env;

        AlgoliaService.reactSearchClient = algoliasearch(
            ALGOLIA_APPLICATION_ID,
            ALGOLIA_SEARCH_API_KEY,
        );;

        return AlgoliaService.getReactSearchClient();
    };
}

export { AlgoliaService };
